import React, { useState } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
} from 'mdb-react-ui-kit';
import { RiMenu3Fill } from "react-icons/ri";
import "../css/navbar.css";

const Navbar = () => {
    const [openNav, setOpenNav] = useState(false);

    return (
        <MDBNavbar expand='md' sticky className='shadow-0 primary-bkg'>
            <MDBContainer className='px-2'>
                <MDBNavbarBrand href='#'><img alt='Logotipo Ecosolux Automotivo' src={process.env.PUBLIC_URL + '/images/utils/logo.svg'} width={'110px'} height={'auto'} /></MDBNavbarBrand>
                <MDBNavbarToggler
                    type='button'
                    aria-controls='navbarContent'
                    aria-expanded={openNav}
                    aria-label='Toggle navigation'
                    onClick={() => setOpenNav(!openNav)}
                >
                    <RiMenu3Fill size={40} className='green-text' />
                </MDBNavbarToggler>
                <MDBCollapse navbar open={openNav} className='justify-content-center' id='navbarContent'>
                    <MDBNavbarNav className='mb-2 mb-lg-0'>
                        <MDBNavbarItem>
                            <MDBNavbarLink className='green-text nav-link-text' href='/'>Oficina</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink className='green-text nav-link-text' href='/produto/dq200'>DQ200</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink className='green-text nav-link-text' href='#contato'>Contato</MDBNavbarLink>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
};

export default Navbar;
