import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { BannerHome } from "../components/banner";
import { PiWhatsappLogo } from "react-icons/pi";
import Services from "../components/services";
import ParallaxSection from "../components/parallaxSection";
import '../css/home.css';
import RedirectCards from "../components/redirectCards";
import Footer from "../components/footer";
import Instagram from "../components/instagram";

const Home = () => {
    return (
        <>
            <BannerHome title="Seu carro fala a"
                highlightTitle="ecosolux"
                postHighlight="entende"
                youtubeEmbed="#"
                buttonCelPhone={{ text: 'Enviar Mensagem', icon: <span><PiWhatsappLogo size={28} /></span>, href: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais!' }}
                description="Inovando com soluções o mercado automotivo para você que busca a melhor qualidade em produto e suporte" />
            <Services />
            <MDBContainer fluid className='parallax-home-container'>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol md={10}>
                        <ParallaxSection />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Instagram />
            <RedirectCards />
            <Footer />
        </>
    )
}

export default Home;