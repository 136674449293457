import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../screens/home';
import Navbar from '../components/navbar';
import Produto from '../screens/produto';
import WhatsAppFloat from '../components/whatsAppFloat';

function RouterController() {
  return (
    <Router>
      <Navbar />
      <WhatsAppFloat />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produto/:slug" element={<Produto />} />
      </Routes>
    </Router>
  );
}

export default RouterController;
