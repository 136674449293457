import React, { useEffect, useRef } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../css/parallax-section.css';
import { PiWhatsappLogo } from "react-icons/pi";

const ParallaxSection = () => {
    const sectionsRef = useRef([]);

    // Dados das seções com o novo bloco adicionado
    const sectionsData = [
        {
            title: 'Problemas Crônicos no Câmbio DSG',
            text: 'Soluções rápidas para falhas comuns no câmbio DSG do Golf e Audi A1/A3, como ruídos e trocas de marcha bruscas. Técnicas avançadas e peças originais garantem um câmbio confiável.',
            buttonText: 'Enviar Mensagem',
            buttonIcon: <PiWhatsappLogo size={30} />,
            buttonLink: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais sobre problemas crônicos do câmbio DSG!'
        },
        {
            title: 'Manutenção Preventiva do Câmbio Automático',
            text: 'Evite problemas com manutenção regular do câmbio automático, incluindo troca de óleo e ajustes para máximo desempenho.',
            buttonText: 'Agende Manutenção',
            buttonIcon: <PiWhatsappLogo size={30} />,
            buttonLink: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais sobre manutenção preventiva de câmbio automático!'
        },
        {
            title: 'Nossa equipe de especialistas emprega tecnologia de ponta',
            text: 'Fazemos testes rigorosos e cuidadosos para garantir que cada bloco hidráulico esteja 100% preparado. Qualidade e experiência comprovada.',
            buttonText: 'Enviar mensagem',
            buttonIcon: <PiWhatsappLogo size={30} />,
            buttonLink: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero falar com um especialista!'
        },
        {
            title: 'Entre em contato agora pelo WhatsApp!',
            text: 'Precisa de ajuda imediata? Nossa equipe está pronta para te atender e resolver suas dúvidas! Clique e fale diretamente conosco pelo WhatsApp para um atendimento rápido e personalizado.',
            buttonText: 'Fale Conosco',
            buttonIcon: <PiWhatsappLogo size={30} />,
            buttonLink: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais!'
        }
    ];

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            sectionsRef.current.forEach((section, index) => {
                if (section) {
                    const rect = section.getBoundingClientRect();
                    const sectionCenter = rect.top + rect.height / 2;
                    const distanceFromCenter = Math.abs(windowHeight / 2 - sectionCenter);

                    // Ajuste de opacidade e escala somente quando o elemento está perto do centro
                    if (distanceFromCenter < windowHeight / 2) {
                        const opacity = 1 - distanceFromCenter / (windowHeight / 2);
                        const scale = 0.5 + (opacity * 0.5); // Expande de 50% a 100%

                        section.style.opacity = opacity > 0 ? opacity : 0;
                        section.style.transform = `scale(${scale}) translateY(${-distanceFromCenter * 0.2}px)`;
                    } else {
                        section.style.opacity = 0;
                        section.style.transform = 'scale(0.5)';
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <MDBContainer fluid className="parallax-container primary-bkg ">
            <MDBRow>
                {/* Coluna Esquerda com Imagem Sticky */}
                <MDBCol md={6} className="sticky-image-col">
                    <img className="parallax-home-img sticky-image" alt='imagem representativa' src={`${process.env.PUBLIC_URL}/images/utils/parallax.jpeg`} />
                </MDBCol>

                {/* Coluna Direita com Conteúdo Expansível e Centralizado */}
                <MDBCol md={6} className="parallax-content-col">
                    {sectionsData.map((section, index) => (
                        <div
                            key={index}
                            className="parallax-section"
                            ref={(el) => (sectionsRef.current[index] = el)}
                        >
                            <h2 className='white-text'>{section.title}</h2>
                            <p className='white-text'>{section.text}</p>
                            <a className="parallax-btn" href={section.buttonLink} rel="noopener noreferrer">{section.buttonIcon}{section.buttonText}</a>
                        </div>
                    ))}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ParallaxSection;
