import { MDBContainer, MDBRow, MDBCol, MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import '../css/faq.css';

const FaqSection = ({ slug }) => {
    return (
        <MDBContainer>
            <MDBRow className="justify-content-center align-items-center" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <span className="line"></span>
            </MDBRow>
            <MDBRow className="about-container">
                <MDBCol md={6} className="d-flex justify-content-center align-items-center">
                    <img
                        className={'img-fluid'}
                        src={`${process.env.PUBLIC_URL}/images/produtos/${slug}/produto/one.svg`}
                        alt="Imagem do Produto"
                    />
                </MDBCol>
                <MDBCol md={6}>
                    {slug === 'dq200' ? (
                        <div className="faq-section">
                            <h2 className='green-text'>FAQ - Placa Mecatrônica DQ200</h2>
                            <MDBAccordion flush initialActive={1}>
                                <MDBAccordionItem collapseId={1} headerTitle="1. A placa DQ200 é nova e vem programada?">
                                    Sim, a placa é nova, com blindagem contra vazamentos. A programação é feita a partir do chassi do veículo, garantindo compatibilidade total.
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={2} headerTitle="2. Como funciona a garantia da placa?">
                                    Oferecemos 3 meses de garantia contra defeitos de fabricação. A instalação deve ser feita por um profissional qualificado para validar a garantia.
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={3} headerTitle="3. E se a placa original estiver queimada?">
                                    Mesmo assim, fazemos a clonagem usando o chassi do veículo, garantindo o funcionamento adequado.
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={4} headerTitle="4. A Ecosolux oferece suporte durante a instalação?">
                                    Sim, nosso suporte está disponível via WhatsApp para orientar e tirar dúvidas técnicas antes, durante e após a instalação.
                                </MDBAccordionItem>

                                <MDBAccordionItem collapseId={5} headerTitle="5. Quanto tempo leva para o envio da placa?">
                                    Enviamos em até 24 horas úteis após a confirmação do pagamento.
                                </MDBAccordionItem>
                            </MDBAccordion>
                        </div>
                    ) : (
                        <div className="faq-section">
                            <h2>FAQ de Outro Produto</h2>
                            <p>O conteúdo da FAQ para este produto será definido em breve.</p>
                        </div>
                    )}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default FaqSection;
