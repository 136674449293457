import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from 'mdb-react-ui-kit';
import '../css/redirect-cards.css';
import { BsDiagram3 } from "react-icons/bs";
import { TbAutomaticGearbox } from "react-icons/tb";

const RedirectCards = ({ slug }) => {
    const [conditionalCard, setConditionalCard] = useState([]);

    useEffect(() => {
        if (slug === 'dq200') {
            <></>
        } else if (slug === 'm6') {
            <></>
        } else {
            setConditionalCard([
                {
                    imgSrc: `${process.env.PUBLIC_URL}/images/produtos/dq200/produto/one.svg`,
                    title: 'Placa Mecatrônica DQ200 com Blindagem Exclusiva',
                    description: 'Nossa placa DQ200 é equipada com blindagem para evitar vazamentos de óleo, garantindo maior durabilidade e segurança para o seu câmbio.',
                    buttonText: 'Conhecer mais sobre DQ200',
                    redirectTo: '/produto/dq200'
                },
            ]);
        }
    }, [slug]);

    // Dados dos outros cartões fixos
    const otherCardsData = [
        {
            icon: <TbAutomaticGearbox size={100} className='green-text' />,
            title: 'Serviços Especializados em Câmbio Automático',
            description: 'Precisa de manutenção ou conserto do câmbio automático? A Ecosolux oferece diagnósticos precisos e reparos de qualidade, garantindo o melhor desempenho para o seu veículo.',
            buttonText: 'Conhecer Oficina',
            redirectTo: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, gostaria de falar sobre Serviços Especializados em Câmbio Automático!',
        },
        {
            icon: <BsDiagram3 size={100} className='green-text' />,
            title: 'Ferramentas para Diagnóstico de Câmbio Automático',
            description: 'Explore nossas soluções de diagnóstico avançado, incluindo o VNCI, essencial para oficinas que desejam precisão e resultados rápidos na manutenção de câmbios automáticos.',
            buttonText: 'Fale com a gente',
            redirectTo: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, gostaria de falar sobre Ferramentas para Diagnóstico de Câmbio Automático!',
        }
    ];

    // Função para redirecionamento
    const handleRedirect = (redirectTo) => {
        window.location.href = redirectTo;
    };

    return (
        <MDBContainer className='cards-container'>
            <MDBRow className="g-4 justify-content-center align-items-center" style={{gap: '0px'}}>
                {/* Primeiro cartão fixo */}
                <MDBCol md="3">
                    <MDBCard className="redirect-card">
                        <div className="icon-container">
                            <span>
                                {otherCardsData[0].icon}
                            </span>
                        </div>
                        <MDBCardBody>
                            <MDBCardTitle className="card-title">{otherCardsData[0].title}</MDBCardTitle>
                            <MDBCardText className="card-text">{otherCardsData[0].description}</MDBCardText>
                            <MDBBtn className="card-button" onClick={() => handleRedirect(otherCardsData[0].redirectTo)}>
                                {otherCardsData[0].buttonText}
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                {/* Cartão(s) condicional(is) no meio */}
                {conditionalCard.map((card, index) => (
                    <MDBCol md="3" key={index}>
                        <MDBCard className="redirect-card">
                            <div className="image-container">
                                <img src={card.imgSrc} alt={card.title} className="card-image" />
                            </div>
                            <MDBCardBody>
                                <MDBCardTitle className="card-title">{card.title}</MDBCardTitle>
                                <MDBCardText className="card-text">{card.description}</MDBCardText>
                                <MDBBtn className="card-button" onClick={() => handleRedirect(card.redirectTo)}>
                                    {card.buttonText}
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                ))}

                {/* Segundo cartão fixo */}
                <MDBCol md="3">
                    <MDBCard className="redirect-card">
                        <div className="icon-container">
                            <span>
                                {otherCardsData[1].icon}
                            </span>
                        </div>
                        <MDBCardBody>
                            <MDBCardTitle className="card-title">{otherCardsData[1].title}</MDBCardTitle>
                            <MDBCardText className="card-text">{otherCardsData[1].description}</MDBCardText>
                            <MDBBtn className="card-button" onClick={() => handleRedirect(otherCardsData[1].redirectTo)}>
                                {otherCardsData[1].buttonText}
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default RedirectCards;
