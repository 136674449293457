import { PiWhatsappLogo } from "react-icons/pi";
import '../css/whatsapp-float.css';


const WhatsAppFloat = () => {
    return (
        <>
            <a href="https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais!" target="_blank" rel="noreferrer" class="whatsapp-float">
                <PiWhatsappLogo size={50}/>
            </a>
        </>
    );
}

export default WhatsAppFloat;