import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import '../css/footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <MDBContainer fluid className="contact-map-container">
                <MDBRow>
                    <h4 className='title-map'>Onde nos encontrar?</h4>
                </MDBRow>
                <MDBRow className="contact-map-section">
                    <MDBCol md="6" className="contact-info" id='contato'>
                        <h4 className="footer-subtitle">Contato Rápido</h4>
                        <p className='eazy-contact'>
                            <span>Telefone: (11) 4258-0121<br />
                                E-mail: <a href="mailto:contato@ecosoluxautomotivo.com">contato@ecosoluxautomotivo.com</a></span>
                            <span>Entre em contato conosco para suporte, dúvidas ou agendamento de serviços especializados em câmbio automático.</span>
                        </p>
                    </MDBCol>
                    <MDBCol md="6" className="map-container">
                        <iframe
                            title="Ecosolux Location"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5165.6855982995485!2d-46.5754846976386!3d-23.722714937765513!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce418110b37eff%3A0x88ab24cb9432c0cd!2sEcosolux%20Solu%C3%A7%C3%B5es%20Automotivas!5e0!3m2!1sen!2sus!4v1731514781469!5m2!1sen!2su"
                            className='map-iframe'
                            style={{ border: '0', borderRadius: '8px' }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <MDBContainer>
                {/* Rodapé com logotipo e informações finais */}
                <MDBRow className="footer-bottom">
                    <MDBCol md="6" className="footer-logo">
                        <img src={`${process.env.PUBLIC_URL}/images/utils/logo-footer.svg`} alt="Ecosolux Logo" />
                        <p>
                            <span>Av. Sen. Ricardo Batista, 631 - Assunção - São Bernardo do Campo/SP
                                <br />
                                CEP: 09812-510</span>

                            <span>Tel: (11) 4258-0121<br />
                                E-mail: <a href="mailto:contato@ecosoluxautomotivo.com" className='green-text'>contato@ecosoluxautomotivo.com</a>
                            </span>
                        </p>
                    </MDBCol>
                    <MDBCol md="6" className="footer-contact-info">
                        <div className="social-icons">
                            <a href="https://facebook.com/ecosoluxautomotivo" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                            </a>
                            <a href="https://instagram.com/ecosolux" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://twitter.com/ecosolux" target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </a>
                            <a href="https://linkedin.com/company/ecosolux" target="_blank" rel="noopener noreferrer">
                                <FaLinkedinIn />
                            </a>
                            <a href="https://youtube.com/ecosolux" target="_blank" rel="noopener noreferrer">
                                <FaYoutube />
                            </a>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </footer>
    );
};

export default Footer;
