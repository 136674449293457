import React from 'react';
import { BsInstagram } from "react-icons/bs";
import '../css/instagram.css';
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';

const Instagram = () => {

    return (
        <MDBContainer fluid>
            <MDBRow className='justify-content-center align-items-center'>
                <MDBCol size={11}>
                    <div className='instagram-container'>
                        <h3><BsInstagram /> INSTAGRAM &nbsp;| <span className="highlight">@ECOSOLUX</span></h3>
                        <div id="instafeed" className="instagram-posts"></div>
                        <div class="elfsight-app-60b92bb7-2f01-4818-b156-6594eed06f3e" data-elfsight-app-lazy></div>
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default Instagram;
