import { BannerProduto } from "../components/banner";
import ProductAbout from "../components/productAbout";
import FaqSection from "../components/FAQ";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import RedirectCards from "../components/redirectCards";
import Footer from "../components/footer";

const Produto = () => {

    const { slug } = useParams();

    useEffect(() => {
        document.title = `${slug.toUpperCase()} - EcoSolux Automotivo`;
    },[]);

    return (
        <>
            <BannerProduto slug={slug} />
            <ProductAbout slug={slug} />
            <FaqSection slug={slug} />
            <RedirectCards slug={slug} />
            <Footer />
        </>
    )
}

export default Produto;