import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit';
import { PiWhatsappLogo } from "react-icons/pi";
import '../css/services.css';

const Services = () => {
    const bkgImage = { backgroundImage: `url('${process.env.PUBLIC_URL}/images/utils/background.svg')` };

    // Dados dos cards atualizados
    const cardsData = [
        {
            title: 'Manutenção Preventiva e Corretiva',
            description: 'Manutenção completa em câmbios automáticos, prevenindo falhas e garantindo a durabilidade do bloco hidráulico e do sistema como um todo.',
            isActive: true,
            icon: `${process.env.PUBLIC_URL}/images/services/s1.svg`,
            href: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais sobre manutenção preventiva e corretiva!'
        },
        {
            title: 'Especialistas em DQ200',
            description: 'Soluções rápidas e eficazes para problemas no câmbio DQ200, com suporte especializado para oficinas em todo o Brasil.',
            isActive: true,
            icon: `${process.env.PUBLIC_URL}/images/services/s2.svg`,
            href: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais sobre o DQ200!'
        },
        {
            title: 'Diagnóstico Avançado',
            description: 'Diagnóstico preciso com ferramentas de última geração e troca de óleo especializada, garantindo o bom funcionamento do câmbio automático.',
            isActive: true,
            icon: `${process.env.PUBLIC_URL}/images/services/s3.svg`,
            href: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais sobre diagnóstico avançado!'
        },
        {
            title: 'Programação Remota de Módulos',
            description: 'Ajustes remotos de módulos eletrônicos com suporte contínuo, oferecendo excelência para oficinas parceiras em todo o País.',
            isActive: true,
            icon: `${process.env.PUBLIC_URL}/images/services/s4.svg`,
            href: 'https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero saber mais sobre programação remota de módulos!'
        }
    ];

    return (
        <MDBContainer fluid className='services-container' style={bkgImage}>
            <MDBRow className="g-4 justify-content-center align-items-center">
                <MDBCol md={7}>
                    <MDBRow>
                        {cardsData.map((card, index) => (
                            <MDBCol md="6" key={index} className=''>
                                <MDBCard className="service-card">
                                    <div className={`icon-container ${card.isActive ? 'active' : 'inactive'}`}>
                                        <div className='icon'>
                                            <img className='img-fluid' alt={`icone do card ${card.title}`} src={card.icon} />
                                        </div>
                                    </div>
                                    <MDBCardBody>
                                        <MDBCardTitle className="service-title">{card.title}</MDBCardTitle>
                                        <MDBCardText className="service-description">{card.description}</MDBCardText>
                                        {card.isActive && (
                                            <a href={card.href} className="whatsapp-button" rel='noreferrer'>
                                                <PiWhatsappLogo size={30}/> Enviar mensagem
                                            </a>
                                        )}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default Services;
