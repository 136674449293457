import { MDBContainer, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import '../css/product-about.css';

const ProductAbout = ({ slug }) => {
    return (
        <MDBContainer>
            <MDBRow className="justify-content-center align-items-center initial-separator-product-about">
                <span className="line"></span>
            </MDBRow>
            <MDBRow className="about-container">
                <MDBCol md={6} className="d-flex justify-content-center align-items-center">
                    <img
                        className={'img-fluid'}
                        src={`${process.env.PUBLIC_URL}/images/produtos/${slug}/produto/one.svg`}
                    />
                </MDBCol>
                <MDBCol md={6}>
                    {slug === 'dq200' ? (
                        <div className="product-info white-text">
                            <h3 className="green-text">Placa Mecatrônica DQ200 com Blindagem Exclusiva</h3>
                            <h5>Durabilidade garantida, proteção contra vazamentos</h5>
                            <p>
                                Nossa placa mecatrônica DQ200 é equipada com uma blindagem inovadora que protege os circuitos internos contra vazamentos de óleo, um problema comum nas versões anteriores. Isso proporciona maior segurança e durabilidade para o seu câmbio, evitando falhas prematuras.
                            </p>

                            <h3 className="green-text">Programação Personalizada e Clonagem Completa</h3>
                            <h5>Compatibilidade total com seu Audi ou Volkswagen</h5>
                            <p>
                                A Ecosolux realiza a programação da nova placa DQ200 a partir do chassi do seu veículo, mesmo que a placa antiga esteja queimada. Isso garante uma instalação perfeita e um funcionamento ideal desde o primeiro uso, já incluído no valor.
                            </p>

                            <h3 className="green-text">Suporte Especializado de Verdade</h3>
                            <h5>Assistência total, antes e depois da compra</h5>
                            <p>
                                Na Ecosolux, você não compra apenas uma placa DQ200, mas também o suporte completo de especialistas em câmbio automático. Garantimos suporte técnico antes da compra e após a instalação, para assegurar que você tenha toda a orientação necessária e que a garantia seja validada sem problemas.
                            </p>
                        </div>
                    ) : (
                        <div className="product-info white-text">
                            {/* Conteúdo padrão para outros produtos */}
                            <h3>Outro Produto</h3>
                            <h5>Subtítulo do outro produto</h5>
                            <p>Descrição do outro produto...</p>
                        </div>
                    )}
                </MDBCol>
            </MDBRow>
            <MDBRow className="justify-content-center align-items-center" style={{ marginTop: '3rem', marginBottom: '3.5rem' }}>
                <span className="line"></span>
            </MDBRow>
            <MDBRow>
                <MDBCol md={4} className="highlight-col">
                    {slug === 'dq200' ? (
                        <>
                            <h2 className="highlight-title green-text">A Solução Definitiva Está na Ecosolux!</h2>
                        </>
                    ) : (
                        <>
                            {/* Conteúdo de destaque para outros produtos */}
                            <h2 className="highlight-title">Título de Outro Produto</h2>
                            <p className="additional-text">Texto adicional para outro produto...</p>
                        </>
                    )}
                </MDBCol>

                <MDBCol md={7}>
                    {slug === 'dq200' && (
                        <MDBTable bordered className="error-table white-text mt-3">
                            <MDBTableHead>
                                <tr>
                                    <th>Código de Erro</th>
                                    <th>Descrição Detalhada</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr>
                                    <td>P17BF - Falha na Mecatrônica</td>
                                    <td>Indica falhas na comunicação interna, podendo causar trancos durante a troca de marchas.</td>
                                </tr>
                                <tr>
                                    <td>P072C - Falha no Controle da Embreagem</td>
                                    <td>Finaliza desgaste das embreagens ou problemas na operação hidráulica, afetando a suavidade das trocas.</td>
                                </tr>
                                <tr>
                                    <td>P17SF - Pressão de Óleo Insuficiente</td>
                                    <td>Relata possíveis vazamentos ou baixa pressão de óleo, exigindo troca ou reparo imediato para evitar danos graves.</td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ProductAbout;
