import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import "../css/banner.css";
import { PiWhatsappLogo } from "react-icons/pi";

const BannerHome = ({ title = '', highlightTitle = '', postHighlight = '', description = '', buttonCelPhone = { text: '', href: '#', icon: '' }, youtubeEmbed = '' }) => {

    return (
        <MDBContainer fluid className="banner-home">
            <MDBRow className="justify-content-center align-items-center">
                <MDBCol md={10} className="text-center">
                    <MDBCard className="card-home">
                        <MDBRow className="justify-content-between align-items-center content-area">
                            <MDBCol md={6} className="banner-container">
                                <h1 className="banner-title">{title} <span>{highlightTitle}</span> {postHighlight}</h1>
                                <p className="banner-description">{description}</p>
                                <a className="button-cel-phone" href={buttonCelPhone.href}>{buttonCelPhone.icon} {buttonCelPhone.text}</a>
                            </MDBCol>

                            <MDBCol md={6} className="d-flex justify-content-center align-items-center">
                                { /*
                                    <iframe width="100%" height="315" src={youtubeEmbed}
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        */
                                }
                                <img className="banner-home-img" alt='Banner home' src={`${process.env.PUBLIC_URL}/images/banner/banner-home.jpeg`} />
                            </MDBCol>


                        </MDBRow>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

const BannerProduto = ({ slug }) => {

    return (
        <MDBContainer fluid className="banner-produto">
            <MDBRow className="justify-content-between align-items-center">
                <MDBCol className="title-col" md={6}>
                    <div className="text-left marcas-container">
                        <img height={'auto'} width={'100px'} alt={`imagem de marca do produto ${slug}`} src={`${process.env.PUBLIC_URL}/images/produtos/${slug}/marcas/one.svg`} />
                        <img height={'auto'} width={slug === 'dq200' ? '170px' : '100px'} alt={`imagem de marcas do produto ${slug}`} src={`${process.env.PUBLIC_URL}/images/produtos/${slug}/marcas/two.svg`} />
                    </div>
                    <div className="page-address">
                        <p className="green-text">Oficina</p>
                        <p className="separator green-text">/</p>
                        <p className="green-text slug">{slug}</p>
                    </div>
                    <h1 className="title white-text">{slug === 'dq200' ? 'DQ 200' : slug === 'm6' ? 'M6' : null}</h1>
                    <div className="description-container">
                        <span className="point"></span>
                        <p className="description white-text">Especialistas em manutenção e conserto do câmbio automático DSG DQ200 para Audi e VW</p>
                    </div>
                    <img className="img-fluid produto-image-mobile" alt={`imagem de produto ${slug}`} src={`${process.env.PUBLIC_URL}/images/produtos/${slug}/produto/one.svg`} />
                </MDBCol>
                <MDBCol md={6} className="cta-col">
                    <img className="img-fluid produto-image" alt={`imagem de produto ${slug}`} src={`${process.env.PUBLIC_URL}/images/produtos/${slug}/produto/one.svg`} />
                    <MDBCard className="cta-card">
                        <MDBRow className="align-items-center justify-content-center">
                            <MDBCol md={9}>
                                <h3 className="cta-title">Fale com o vendedor</h3>
                                <p className="cta-description">Está com problemas no câmbio DQ200 do seu Audi ou Volkswagen? Fale agora mesmo com um de
                                    nossos especialistas e garanta um diagnóstico preciso e um reparo seguro.</p>
                                <a className="cta-btn" href={`https://api.whatsapp.com/send?phone=5511965848738&text=Olá, quero falar com um vendedor sobre o ${slug}!`} target="_blank" rel="noreferrer"><PiWhatsappLogo size={30} />Falar no WhatsApp</a>
                            </MDBCol>
                            <MDBCol md={3}>

                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export { BannerHome, BannerProduto };